import React, { useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';


export const NavMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const { home, links } = props;

  const menuItems = links.map((item, key) =>
    <NavItem key={item.name}>
      <NavLink onClick={() => { setCollapsed(true) }} tag={Link} className="text-dark" to={item.url}>{item.name}</NavLink>
    </NavItem>
  );

  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-0" light>
        <Container>
          <NavbarBrand tag={Link} to={home}>RESOURCEPOINT <img src="https://i.imgur.com/4RU92kT.png" /></NavbarBrand>
          <NavbarToggler onClick={() => { setCollapsed(!collapsed) }} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              {menuItems}
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  );
}